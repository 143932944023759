import parsePhoneNumber, { CountryCode, PhoneNumber } from "libphonenumber-js";

type Region = "uk" | "us";
const ALL_NON_DIGIT = /\D/g;

function convertRegionToCountryCode(region: "uk" | "us"): CountryCode {
  return {
    us: "US",
    uk: "GB",
  }[region] as CountryCode;
}

function getInternationalNumber(rawPhoneNumber: string, region: Region): string | undefined {
  const defaultCountry: CountryCode = convertRegionToCountryCode(region);
  const phoneNumber: PhoneNumber | undefined = parsePhoneNumber(rawPhoneNumber, defaultCountry);

  if (!phoneNumber?.isValid()) {
    return undefined;
  }

  return phoneNumber.formatInternational();
}

export function normalisePhoneNumber(rawPhoneNumber: string, region: Region): string | undefined {
  return getInternationalNumber(rawPhoneNumber, region)?.replace(ALL_NON_DIGIT, "");
}

export function normaliseString(str: string): string {
  return str.trim().toLowerCase();
}

export function normaliseDate(strDate: string): string {
  return strDate.trim().replace(/-/g, "");
}

export function normaliseGender(isMale: boolean): string {
  return isMale ? "m" : "f";
}
